<template>
  <div class="red-list">
    <div class="header">
      <div class="bg"><img :src="redIcon" alt=""></div>
    </div>
    <div class="content">
      <div class="head">
        <div class="title">恭喜你</div>
        <div class="des">获得新人奖励</div>
      </div>
      <div class="list">
        <div v-for="item in list" :key="item.id" class="item">
          <div class="head">
            <template v-if="item.couponsType == 1">
              <div class="num"><span>{{ (item.percentOff / 10).toFixed(1) }}折</span></div>
              <div class="tip"><span>折扣券</span></div>
            </template>
            <template v-else>
              <div class="num">￥<span>{{ item.priceOff * 1 / 100 }}</span></div>
              <div class="tip"><span>抵扣券</span></div>
            </template>
          </div>
          <div class="cont">
            <div class="title">{{ item.couponsTitle }}</div>
            <div class="bottom">
              <div class="date">有效期至 : {{ item.validEndTime | parseTime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="btn">
        <van-button
          block
          round=""
          type="info"
          color="#F45B3F"
          @click="goConfirm"
        >
          我知道了，领取礼包
        </van-button>
      </div>
      <div class="des">【个人中心】-【我的卡券】可查看您的优惠券</div>
    </div>
  </div>
</template>
<script>
import redIcon from '@/assets/location/money@2x.png'
import { parseTime } from '@/utils/index'
export default {
  filters: { parseTime },
  data() {
    return {
      list: [],
      redIcon
    }
  },
  mounted() {
    this.list = this.$route.params.datas || []
    if (this.list.length === 0) {
      this.$router.go(-1)
    }
  },
  methods: {
    goConfirm() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
.red-list {
  .content {
    .head {
      text-align: center;
      .title {
        padding-top: 17px;
        font-weight: bold;
        font-size: 16px;
      }
      .des {
        padding-top: 8px;
        font-size: 14px;
      }
    }
  }
  .list {
    padding: 10px 0;
    .item {
      display: flex;
      width: 361px;
      height: 122px;
      background-image:url(~@/assets/user/coupon@2x.png);
      background-size: 100%;
      .head {
        width: 106px;
        padding: 16px 0 0 9px;
        color: #fff;
        .city {
          padding-left: 10px;
        }
        .num {
          padding-top: 10px;
          text-align: center;
          span {
            font-size: 24px;
          }
        }
        .tip {
          display: flex;
          justify-content: center;
          span {
            margin-top: 10px;
            border: 1px solid #fff;
            font-size: 10px;
            padding: 4px 8px;
            border-radius: 16px;
          }
        }
      }
      .cont {
        display: flex;
        flex-flow: column;
        justify-content: center;
        width: 200px;
        padding: 0 0 0 16px;
        .title {
          font-size: 16px;
        }
        .bottom {
          display: flex;
          padding-top: 10px;
          font-size: 12px;
          .date {
            flex: 1;
            color: #999999;
          }
        }
      }
    }
  }
  .bottom {
    .btn {
      padding: 0 40px;
    }
    .des {
      padding-top: 10px;
      color: #CCCCCC;
      font-size: 12px;
      text-align: center;
    }
  }
}
</style>
